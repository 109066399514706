<template>
  <div
    class="user-avatar w-[30px] h-[30px] rounded-[50%] flex justify-center items-center"
    @click="$emit('click', $event)"
  >
    <div class="initials text-xs font-semibold uppercase">{{ userInitials }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';

const props = defineProps({
  user: { type: Object as PropType<API.LoggedInUserInfo>, required: true },
});

defineEmits(['click']);

const userInitials = computed(() => props.user.givenName[0] + props.user.familyName[0]);
</script>

<style scoped lang="scss">
@import '../../../styles/colors';

.user-avatar {
  background: var(--portal-user-avatar-bg-color);

  .initials {
    color: var(--portal-user-avatar-text-color);
  }
}
</style>
