<template>
  <component :is="NavComponent" :data="item" @close="close" />
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import NavbarLink from '@/components/navbar/main/NavbarLink.vue';
import NavbarMenu from '@/components/navbar/main/NavbarMenu.vue';
import SidebarMenu from '@/components/navbar/mobile/sideDrawer/SidebarMenu.vue';
import type { NavigationWidget } from '@/types/storyblok';

const props = defineProps({
  item: { type: Object as PropType<NavigationWidget['items'][0]>, required: true },
  isMobile: { type: Boolean, default: false },
});
const NavComponent = computed(() => {
  if (props.item.component === 'NavigationMenuItem') {
    if (props.isMobile) return SidebarMenu;
    return NavbarMenu;
  }
  return NavbarLink;
});

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};
</script>
