<template>
  <!-- Logged out state -->
  <div v-if="userInfo.status === 'logged-out'" class="user-controls">
    <a :href="signInUrl" type="button">
      <v-button :label="$t('navbar.sign_in')" appearance="filled" connotation="accent" />
    </a>
    <a
      href="/sign-up?&utm_campaign=bizdirect&attribution_campaign=bizdirect&icid=tryitfree_api-developer-adp_nexmodashbdfreetrialsignup_nav&adobe_mc=MCMID%3D54513262962993135963256534253007039499%7CMCORGID%3DA8833BC75245AF9E0A490D4D%2540AdobeOrg%7CTS%3D1710447635&icid=tryitfree_api-developer-adp_nexmodashbdfreetrialsignup_nav"
      type="button"
      class="vvd-scheme-main"
    >
      <v-button :label="$t('navbar.sign_up')" appearance="filled" connotation="cta" />
    </a>
  </div>
  <!-- Logged in state -->
  <navbar-user-menu v-else-if="userInfo.status === 'logged-in'" :user="userInfo" />
  <!-- Issue with account -->
  <div v-else class="account-issue">
    <v-icon connotation="alert" type="warning-solid" :size="-6" />
    <div class="message">
      <div>{{ $t('navbar.issueWithYourAccount') }}</div>
      <div>
        <i18n-t tag="div" keypath="navbar.pleaseNavigateTo" scope="global">
          <template #dashboardLink>
            <a href="https://dashboard.nexmo.com/" target="_blank" rel="noopener">
              {{ $t('navbar.dashboard') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VButton, VIcon } from '@vonage/vivid-vue';
import NavbarUserMenu from '@/components/navbar/main/NavbarUserMenu.vue';
import { computed, type PropType } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const signInUrl = computed(() => `/sign-in?redirect=${encodeURI(route.fullPath)}`);

defineProps({
  userInfo: { type: Object as PropType<API.UserInfo> | undefined },
});
</script>

<style scoped lang="scss">
.user-controls {
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 16px;

  a {
    color: unset;
    text-decoration: unset;
  }
}

.account-issue {
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 8px;

  .message {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
