<template>
  <div>
    <div class="curtain fixed left-0 right-0 bottom-0" :class="{ open: open }" @click="close" />
    <div
      :class="[
        'drawer flex flex-col fixed overflow-hidden z-[1000] left-0 min-w-[350px] w-[30vw] pt-6 pb-0 pl-0 pr-0',
        { open: open },
      ]"
      @click="onNavbarClick"
    >
      <v-icon v-if="open" name="close-line" class="close-icon pt-0 pb-4 px-8 pointer" :size="-4" @click="close" />
      <div id="sidebar-link-section" class="overflow-y-auto">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VIcon } from '@vonage/vivid-vue';

defineProps({
  open: { type: Boolean, required: true },
});

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};

const onNavbarClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  // close drawer if we click a link
  if (target.tagName.toLowerCase() === 'vwc-list-item' && target.getAttribute('slot') !== 'header') {
    close();
  }
};
</script>

<style scoped lang="scss">
.curtain {
  top: var(--bifrost-header-height);
  display: none;
  &.open {
    display: block;
  }
}

.drawer {
  top: var(--bifrost-header-height);
  height: calc(100vh - var(--bifrost-header-height));
  background-color: #000;
  transform: translateX(-100%);
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  box-sizing: border-box;
  &.open {
    transform: translateX(0);
  }
}

.close-icon {
  color: var(--vvd-color-canvas);
}
</style>
