<template>
  <div class="navbar">
    <div class="navigation">
      <navbar-item v-for="item in items" :key="item._uid" :item="item" />
    </div>
    <div class="separator" />
    <ClientOnly>
      <navbar-search-bar v-if="!isSearchPage" class="search-bar" />
      <navbar-user-info v-if="userInfo" :user-info="userInfo" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import NavbarItem from '@/components/navbar/common/NavbarItem.vue';
import NavbarUserInfo from '@/components/navbar/main/NavbarUserInfo.vue';
import NavbarSearchBar from '@/components/navbar/main/NavbarSearchBar.vue';
import { computed, type PropType } from 'vue';
import { useRoute } from 'vue-router';
import type { NavigationWidget } from '@/types/storyblok';

defineProps({
  items: {
    type: Array as PropType<NavigationWidget['items']>,
    required: true,
  },
  userInfo: { type: Object as PropType<API.UserInfo> | undefined },
});
const route = useRoute();
const isSearchPage = computed(() => route.path === '/search');
</script>

<style scoped lang="scss">
.navbar {
  background-color: var(--portal-main-navbar-desktop-bg-color);
  color: var(--portal-main-navbar-desktop-text-color);
  height: 56px;
}

.navigation {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.separator {
  flex-grow: 1;
}
</style>
