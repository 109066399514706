<template>
  <div class="navbar-container developer-portal">
    <mobile-navbar class="mobile-navbar" :data="story.content" :user-info="userStore.info" />
    <desktop-navbar class="desktop-navbar" :data="story.content" :user-info="userStore.info" />
  </div>
</template>

<script setup lang="ts">
import DesktopNavbar from '@/components/navbar/DesktopNavbar.vue';
import MobileNavbar from '@/components/navbar/MobileNavbar.vue';
import { useUserStore } from '@/store/user';
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import { useBrandingStore } from '@/store/branding';
import type { NavigationWidgetStory } from '@/types/storyblok';

const userStore = useUserStore();
const brandingStore = useBrandingStore();

const story: NavigationWidgetStory = await useAsyncStoryblokWithI18n(
  `branding/${brandingStore.data?.vendorId}/config/navigation`
);
</script>

<style scoped lang="scss">
@import '../../styles/media';
.navbar-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow-y: hidden;

  width: 100%;
  height: var(--bifrost-header-height);
}
.desktop-navbar {
  display: none;

  @media screen and (min-width: $laptop) {
    display: block;
  }
}

.mobile-navbar {
  @media screen and (min-width: $laptop) {
    display: none;
  }
}
</style>
