<template>
  <div>
    <div id="langButton" class="button h-8 flex flex-row items-center gap-2 py-0 px-4 pointer" @click="open = !open">
      <span class="flag w-4 flex mr-1 items-center" v-html="currentLocale.flag" />
      <span>{{ currentLocale.code }}</span>
      <v-icon class="icon" name="chevron-down-line" :size="-6" />
    </div>
    <v-menu
      id="menu"
      auto-dismiss
      class="vvd-theme-alternate"
      corner="BOTTOM_RIGHT"
      menu-corner="END"
      anchor="langButton"
      fixed
      :open="open"
    >
      <v-menu-item
        v-for="locale in locales"
        :key="locale.id"
        :selected="currentLocale.code === locale.id"
        :text="locale.label"
        class="lang-menu-item"
        @click="locale.onClick"
      >
      </v-menu-item>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { VIcon, VMenu, VMenuItem } from '@vonage/vivid-vue';
import { useI18n } from 'vue-i18n';
import { getFlagForLocale } from '@/utils/locales';
import { supportedLocales } from '@/utils/i18n';

const i18n = useI18n();
const open = ref(false);
const currentLocale = computed(() => ({ code: i18n.locale.value, flag: getFlagForLocale(i18n.locale.value) }));
const locales = computed(() =>
  supportedLocales.map(({ locale }) => ({
    id: locale,
    label: getLabelForLocale(locale),
    onClick: () => {
      open.value = false;
      i18n.locale.value = locale;
    },
  }))
);
</script>

<style scoped lang="scss">
.button {
  span {
    font: var(--vvd-typography-base-condensed-bold);
    text-transform: uppercase;
  }
  &:hover {
    background: rgba(white, 0.1);
  }
}
.flag :deep(svg) {
  height: 100%;
  width: 16px;
}
.icon {
  color: var(--vvd-color-canvas);
}
.lang-menu {
  background-color: rgb(37, 37, 37);
  color: var(--vvd-color-canvas);
}
.lang-menu-item {
  --vvd-menu-item-accent-primary: var(--vvd-color-canvas-text);
  --vvd-menu-item-accent-faint: var(--vvd-color-neutral-100);
  --vvd-menu-item-accent-soft: var(--vvd-color-neutral-100);
  --vvd-menu-item-accent-dim: var(--vvd-color-neutral-100);
  --vvd-menu-item-accent-pale: var(--vvd-color-neutral-100);
}
</style>
