<template>
  <div ref="container" class="container">
    <v-text-field
      v-model="query"
      class="search-field vvd-theme-alternate"
      placeholder="Search"
      shape="pill"
      icon="search-line"
      outlined
      dense
      @focus="searchActive = true"
      @keypress="searchActive = true"
      @keyup.enter="$router.push({ path: '/search', query: { query } })"
    />
    <Teleport to="#overlay-target">
      <v-menu
        v-if="showResults"
        ref="dropdown"
        :anchor="container"
        placement="bottom-end"
        open
        style="--menu-block-size: 100%"
      >
        <search-results-overview v-if="results" class="search-results" :query="query" :results="results" />
      </v-menu>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { VMenu, VTextField } from '@vonage/vivid-vue';
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { debounce } from 'lodash-es';
import SearchResultsOverview from '@/components/search/SearchResultsOverview.vue';
import { getQuickResultsForQuery, type QuickSearchResults } from '@/types/search/search';

const container = ref<HTMLElement>();

// Algolia search
const results = ref<QuickSearchResults | null>(null);
const performSearch = async (text: string) => {
  if (text.length) {
    results.value = await getQuickResultsForQuery(text);
  } else {
    results.value = null;
  }
};
const debouncedSearch = debounce(performSearch, 250);

// Perform search when query changes
const query = ref('');
watchEffect(() => {
  debouncedSearch(query.value);
});

// Whether to show the results
const searchActive = ref(false);
const showResults = computed(() => searchActive.value && query.value !== '' && results.value !== null);

// Close results when clicking outside
const onClickOutside = (event: MouseEvent) => {
  if (!container.value || !event.target) {
    return;
  }

  if (searchActive.value && !container.value.contains(event.target as Node)) {
    searchActive.value = false;
  }
};
onMounted(() => {
  document.addEventListener('click', onClickOutside);
});
onUnmounted(() => {
  document.removeEventListener('click', onClickOutside);
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  align-items: center;
}

.search-field {
  width: 320px;
}

.search-results {
  // HACK: remove padding of vivid dropdown
  margin-top: -8px;
  margin-bottom: -8px;
  width: 65vw;
  overflow-y: auto;
}
</style>
