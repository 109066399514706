<template>
  <MobileDrawer :open="open" @close="$emit('close')">
    <v-nav>
      <a
        v-for="item in menu"
        :key="item.id"
        :href="item.link"
        type="button"
        :target="item.target ?? '_self'"
        class="nav-link-button py-[5px] px-5"
      >
        <navbar-button>
          {{ $t(`navigation.${item.id}`) }}
        </navbar-button>
      </a>
    </v-nav>
  </MobileDrawer>
</template>
<script setup lang="ts">
import { VNav } from '@vonage/vivid-vue';
import MobileDrawer from '@/components/navbar/mobile/sideDrawer/MobileDrawer.vue';
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';
import { useBrandingStore } from '@/store/branding';

const brandingStore = useBrandingStore();

defineProps({
  open: { type: Boolean, required: true },
});

/* added this to avoid linting warnings */
defineEmits(['close']);

const menu = [
  { id: 'profile', link: '/profile', target: '_blank' },
  {
    id: 'my-dashboard',
    link: brandingStore.data?.customerDashboardUrl,
    target: '_blank',
  },
  { id: 'logout', link: '/logout' },
];
</script>

<style scoped lang="scss">
.nav-link-button {
  text-decoration: none;
}
</style>
