import * as Flags from 'country-flag-icons/string/3x2';
import { getNavigatorLanguages } from '@/i18n.config';

const CountryCodeRegex = /^(?<lang>[a-z]{2})-(?<country>[A-Z]{2})$/;

const getCountryCode = (lang: string, locale: string) => {
  const match = CountryCodeRegex.exec(locale);
  return match && match.groups && match.groups.lang === lang && match.groups.country;
};

const getFlagFromBrowserCountryCode = (lang: string): string | null => {
  const languages = getNavigatorLanguages(window.navigator);
  const flags = Flags as Record<string, string>;
  if (languages && languages.length > 0) {
    const countryCodes = languages.map(locale => getCountryCode(lang, locale)).filter(Boolean) as string[];
    const flagCode = countryCodes.find(code => code in flags);
    if (flagCode) {
      return flags[flagCode];
    }
  }
  return null;
};

const LocaleToLabel: Record<string, string> = {
  en: 'English',
  cn: '中文',
  ja: '日本語',
};

const LocaleToFlag: Record<string, string> = {
  // english is spoken in many countries so we check the country code
  // to give us the correct flag
  en: (process.client && getFlagFromBrowserCountryCode('en')) ?? Flags.US,
  cn: Flags.CN,
  ja: Flags.JP,
};

export const getLabelForLocale = (id: string): string => LocaleToLabel[id] ?? id;

export const getFlagForLocale = (id: string): string => LocaleToFlag[id];
