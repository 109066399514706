<template>
  <span class="mobile-navbar-flag h-5 pointer" @click="$emit('click', $event)" v-html="currentLocale.flag" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getFlagForLocale } from '@/utils/locales';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const currentLocale = computed(() => ({
  code: i18n.locale.value,
  flag: getFlagForLocale(i18n.locale.value),
}));

defineEmits(['click']);
</script>

<style scoped lang="scss">
.mobile-navbar-flag :deep(svg) {
  height: 100%;
  width: 20px;
}
</style>
