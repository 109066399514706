<template>
  <router-link :custom="true" :to="to">
    <template #default="{ href, route, isActive, isExactActive }">
      <slot
        :href="href"
        :route="route"
        :navigate="customNavigate"
        :is-active="isActive"
        :is-exact-active="isExactActive"
      />
    </template>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { isAbsoluteUrl } from '@/utils/url';
import { useRouter } from 'vue-router';

const i18n = useI18n();
const router = useRouter();

const props = defineProps({
  to: { type: String, required: true },
});

const customNavigate = () => {
  return isAbsoluteUrl(props.to) ? window.open(props.to, '_blank') : router.push(`/${i18n.locale.value}${props.to}`);
};
</script>
