<template>
  <MobileDrawer :open="open" @close="$emit('close')">
    <div v-for="locale in locales" :key="locale.id" class="flex flex-row" @click="locale.onClick">
      <div class="item w-full relative font-semibold text-base py-[5px] px-[34px]" @click="$emit('flag-click', $event)">
        <div class="flex items-center justify-between h-10 pointer">
          <div class="flex items-center">
            <div class="flag-section inline-block mr-2">
              <span class="flag h-[18px] w-[18px] flex items-center mr-1" v-html="locale.flag" />
            </div>
            <div style="color: var(--vvd-color-canvas)">
              {{ locale.label }}
            </div>
          </div>
          <div class="line-icon-section">
            <v-icon
              v-if="currentLocale.code === locale.id"
              slot="meta"
              name="check-line"
              class="icon h-5 w-5"
              :size="-5"
            />
          </div>
        </div>
      </div>
    </div>
  </MobileDrawer>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { VIcon } from '@vonage/vivid-vue';
import { supportedLocales } from '@/utils/i18n';
import { getFlagForLocale, getLabelForLocale } from '@/utils/locales';
import MobileDrawer from '@/components/navbar/mobile/sideDrawer/MobileDrawer.vue';
import { useI18n } from 'vue-i18n';

defineProps({
  open: { type: Boolean, required: true },
});

/* added this to avoid linting warnings */
defineEmits(['close', 'flag-click']);

const i18n = useI18n();

const currentLocale = computed(() => ({ code: i18n.locale.value, flag: getFlagForLocale(i18n.locale.value) }));
const locales = computed(() =>
  supportedLocales.map(({ locale }) => ({
    id: locale,
    label: getLabelForLocale(locale),
    flag: getFlagForLocale(locale),
    onClick: () => {
      i18n.locale.value = locale;
    },
  }))
);
</script>
<style scoped lang="scss">
.item {
  color: var(--vvd-color-canvas);
  --vvd-menu-item-accent-primary: var(--vvd-color-canvas);

  &:hover {
    background: #333333;
  }
}
.icon {
  color: var(--vvd-color-canvas);
}

.flag :deep(svg) {
  height: 100% !important;
  width: auto !important;
}
</style>
