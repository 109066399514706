<template>
  <router-link to="/">
    <div class="logo md:block lg:flex lg:flex-row bg-no-repeat bg-auto bg-center bg-left items-center cursor-pointer" />
  </router-link>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '../../../styles/media';
.logo {
  background-image: var(--portal-logo-mobile-bg-image);
  width: var(--portal-logo-mobile-bg-width);
  height: var(--portal-logo-mobile-bg-height);

  @media screen and (min-width: $laptop) {
    background-image: var(--portal-logo-desktop-bg-image);
    width: var(--portal-logo-desktop-bg-width);
    height: var(--portal-logo-desktop-bg-height);
  }
}
</style>
