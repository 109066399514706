<template>
  <component :is="wrapper.component" class="link" v-bind="wrapper.props" type="button">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { isAbsoluteUrl } from '@/utils/url';

const props = defineProps({
  link: { type: String, required: true },
});

const i18n = useI18n();
const wrapper = computed(() =>
  isAbsoluteUrl(props.link)
    ? { component: 'a', props: { href: props.link } }
    : {
        component: 'router-link',
        props: { to: `/${i18n.locale.value}${props.link}` },
      }
);
</script>

<style scoped lang="scss">
.link {
  color: unset;
  text-decoration: unset;
  &:hover {
    text-decoration: unset !important;
  }
}
</style>
