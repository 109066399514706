<template>
  <navigation-link :link="data.link" class="py-[5px] px-5 lg:py-0 lg:px-0">
    <navbar-button @click="close">
      {{ data.label }}
    </navbar-button>
  </navigation-link>
</template>

<script setup lang="ts">
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';
import NavigationLink from '@/components/navbar/common/NavigationLink.vue';
import { type PropType } from 'vue';
import type { NavigationLinkItem } from '@/types/storyblok';

defineProps({
  data: { type: Object as PropType<NavigationLinkItem>, required: true },
});
const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};
</script>
