<template>
  <div>
    <div class="navbar flex justify-between items-center h-full py-3 px-6 box-border">
      <div>
        <NavbarLogo />
      </div>
      <div id="icons" class="flex items-center gap-[18px]">
        <ClientOnly>
          <!-- Language selection -->
          <MobileFlag @click="toggleMenu('lang-select')" />
        </ClientOnly>
        <!-- Search -->
        <router-link :custom="true" to="/search">
          <template #default="{ navigate, isActive }">
            <MobileIcon :icon="isActive ? 'search-solid' : 'search-line'" @click="navigate" />
          </template>
        </router-link>
        <!-- User info -->
        <a v-if="userInfo.status === 'logged-out'" href="/sign-in">
          <MobileIcon icon="user-line" />
        </a>
        <MobileIcon v-else-if="userInfo.status === 'account-issue'" icon="warning-line" />
        <MobileAvatar v-else :user="userInfo" @click="toggleMenu('user-menu')" />
        <!-- Navigation menu -->
        <MobileIcon
          :icon="activeMenu === 'navigation' ? 'close-line' : 'menu-line'"
          @click="toggleMenu('navigation')"
        />
      </div>
    </div>
    <!-- Hamburger Section on Mobile SideDrawer -->
    <MobileSidebar :open="activeMenu === 'navigation'" :data="data" @close="activeMenu = null" />
    <!-- Lang Menu Section on Mobile SideDrawer -->
    <ClientOnly>
      <MobileLangMenu :open="activeMenu === 'lang-select'" @close="activeMenu = null" @flag-click="activeMenu = null" />
    </ClientOnly>
    <!-- Logged In User Menu on Mobile SideDrawer -->
    <MobileUserMenu :open="activeMenu === 'user-menu'" @close="activeMenu = null" />
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue';
import MobileIcon from '@/components/navbar/mobile/MobileIcon.vue';
import MobileAvatar from '@/components/navbar/mobile/MobileAvatar.vue';
import MobileSidebar from '@/components/navbar/mobile/sideDrawer/MobileSidebar.vue';
import MobileUserMenu from '@/components/navbar/mobile/sideDrawer/MobileUserMenu.vue';
import MobileFlag from '@/components/navbar/mobile/MobileFlag.vue';
import type { NavigationWidget } from '@/types/storyblok';
import NavbarLogo from '@/components/navbar/common/NavbarLogo.vue';
import MobileLangMenu from './mobile/sideDrawer/MobileLangMenu.vue';

defineProps({
  data: {
    type: Object as PropType<NavigationWidget>,
    required: true,
  },
  userInfo: { type: Object as PropType<API.UserInfo> | undefined },
});

type MenuKind = 'lang-select' | 'navigation' | 'user-menu';
const activeMenu = ref<MenuKind | null>(null);
const toggleMenu = (menu: MenuKind) => {
  activeMenu.value = activeMenu.value === menu ? null : menu;
};
</script>

<style scoped lang="scss">
@import '../../styles/colors';

.navbar {
  background: var(--portal-navbar-mobile-bg-color);
  color: var(--portal-navbar-mobile-text-color);
}
</style>
