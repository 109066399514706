<template>
  <div id="userMenuSection" ref="userMenuDropDown" class="userMenu" @click="isMenuOpen = !isMenuOpen">
    <navbar-button no-border class="navbar-button">
      <div class="user-icon">
        <span>{{ userInitials }}</span>
      </div>
      <template #icon>
        <v-icon class="icon" name="chevron-down-line" :size="-6" />
      </template>
    </navbar-button>
    <div id="userMenuDropdown" :class="[isMenuOpen ? 'show' : '', 'userMenu-content']">
      <a v-for="item in menu" :id="item.id" :key="item.id" :href="item.link" :target="item.target">
        <v-icon :name="item.icon" class="user-menu-icon" :size="-6" />
        {{ $t(`navigation.${item.id}`) }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref } from 'vue';
import { VIcon } from '@vonage/vivid-vue';
import { useBrandingStore } from '@/store/branding';
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';

type VIconProps = InstanceType<typeof VIcon>['$props'];
type IconType = VIconProps['name'];

const userMenuDropDown = ref<HTMLElement>();
const isMenuOpen = ref(false);
const brandingStore = useBrandingStore();

const props = defineProps({
  user: { type: Object as PropType<API.LoggedInUserInfo>, required: true },
});

const userInitials = computed(() => props.user.givenName[0] + props.user.familyName[0]);

const menu = [
  { id: 'profile', icon: 'profile-line' as IconType, link: '/profile', target: '_blank' },
  {
    id: 'my-dashboard',
    icon: 'gear-line' as IconType,
    link: brandingStore.data?.customerDashboardUrl,
    target: '_blank',
  },
  { id: 'logout', icon: 'exit-line' as IconType, link: '/logout' },
];
const handleClickOutside = (event: MouseEvent) => {
  if (!userMenuDropDown.value || (event.target && !userMenuDropDown.value.contains(event.target as Node))) {
    isMenuOpen.value = false;
  }
};
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped lang="scss">
.navbar-button {
  margin: 0 8px 0 16px;
  cursor: pointer;

  .user-icon {
    span {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;

      color: var(--portal-user-avatar-text-color);
      background: var(--portal-user-avatar-bg-color);

      border-radius: 20px;
    }
  }
}

.icon {
  padding-left: 8px;
  color: var(--vvd-color-canvas);
}

a {
  text-decoration: unset;
}
.menu {
  --menu-min-inline-size: 158px;
}

.userMenu {
  position: relative;
  display: inline-block;
}

.userMenu-content {
  display: none;
  position: fixed;
  background-color: rgb(37, 37, 37);
  min-width: 170px;
  overflow: auto;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0 6px 8px,
    rgba(0, 0, 0, 0.05) 0 12px 16px,
    rgba(0, 0, 0, 0.1) 0 4px 24px;
  z-index: 1000;
  right: 0;
}

.userMenu-content a {
  color: var(--vvd-color-canvas) !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.userMenu a:hover,
a:visited,
a:focus,
a:active {
  background-color: rgb(49, 49, 49);
}
.show {
  display: block;
}
.user-menu-icon {
  color: var(--vvd-color-canvas);
  margin-right: 10px;
}
</style>
